import React from "react"

import DocumentLayout from "../../components/layout/document"

export default () => {
  return (
    <DocumentLayout title="Android SDK">
      <div className="headline">
        <h1 className="text-primary m-0">Android SDK</h1>
      </div>
      <p className="text-muted">Please contact Liquid Team for details.</p>
    </DocumentLayout>
  )
}
